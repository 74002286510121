import React from 'react'
import { HStack, Text, Token } from '@revolut/ui-kit'
import { AiIcon } from './AiIcon/AiIcon'
import { UpdatedContentBadge } from './UpdatedContentBadge/UpdatedContentBadge'

interface SectionStatusProps {
  title: React.ReactNode
  isGeneratedByAi?: boolean
  isEdited?: boolean
  isLoading?: boolean
}

export const SectionStatus = ({
  title,
  isGeneratedByAi = false,
  isEdited = false,
  isLoading = false,
}: SectionStatusProps) => {
  return (
    <HStack gap="s-6" align="center">
      <Text variant="emphasis1" color={Token.color.foreground}>
        {title}
      </Text>

      {isGeneratedByAi && <AiIcon />}
      <UpdatedContentBadge isEdited={isEdited} isLoading={isLoading} />
    </HStack>
  )
}

export default SectionStatus

import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ActionButton,
  ActionButtonSkeleton,
  Banner,
  Circle,
  Flex,
  Group,
  Icon,
  ProgressStep,
  ProgressSteps,
  Text,
  Token,
} from '@revolut/ui-kit'
import {
  FinalGrade,
  FinalGradeInterface,
  PerformanceSelector,
  ReviewCategory,
  SingleTimelineEventInterface,
} from '@src/interfaces/performance'
import { canAddTeamKpi, selectUser } from '@src/store/auth/selectors'
import { EmployeeInterface } from '@src/interfaces/employees'
import {
  getTimelineStepDescription,
  getTimelineStepState,
} from '@src/pages/EmployeeProfile/Layout/Performance/utils'
import { navigateTo } from '@src/actions/RouterActions'
import { pathToUrl } from '@src/utils/router'
import { ROUTES } from '@src/constants/routes'
import { useCanViewMeetingsTab } from '@src/pages/EmployeeProfile/Preview/ProfileSummary/common'
import { NominateForPromotion } from '@src/pages/EmployeeProfile/Preview/components/Buttons/Actions/NominateForPromotion'
import { ViewNominationButton } from '@src/pages/EmployeeProfile/Preview/components/Buttons/Actions/ViewNominationButton'
import { StepReviewersList } from '@src/pages/EmployeeProfile/Preview/Performance/ProbationTimeline/StepReviewersList'
import { Statuses } from '@src/interfaces'
import { SectionLoader } from '@src/pages/EmployeeProfile/Layout/Performance/SectionLoader'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { captureException } from '@sentry/react'
import { createDraftGoal } from '@src/api/goals'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { useGetSelectors } from '@src/api/selectors'
import { GoalContentType } from '@src/interfaces/goals'
import { selectorKeys } from '@src/constants/api'
import { PermissionTypes } from '@src/store/auth/types'
import { PerfomanceStepDetails } from './PerfomanceStepDetails'
import { useGetPerformanceSettings } from '@src/api/performanceSettings'
import { ReviewCycleStatus } from '@src/interfaces/reviewCycles'
import { useFetchPerformanceSummary } from '../../Preview/Performance/Summary/hooks'
import { useGetGoalsWithContentTypeCheck } from '@src/api/goals'
import { useGetGoalsFilters } from './useGetGoalsFilters'
import { isAfter, isBefore, isPast } from 'date-fns'
import { transparentThemeBackgroundOverrides } from '@src/styles/theme'

enum ButtonStates {
  Add = 'Add',
  Edit = 'Edit',
  Continue = 'Continue',
}

const ButtonTitles = {
  [ButtonStates.Add]: 'Add Review',
  [ButtonStates.Edit]: 'Edit Review',
  [ButtonStates.Continue]: 'Continue Review',
}

const ButtonIcon = {
  [ButtonStates.Add]: 'Plus' as const,
  [ButtonStates.Edit]: 'Pencil' as const,
  [ButtonStates.Continue]: 'Pencil' as const,
}

interface Props {
  data: EmployeeInterface
  selectedPeriod: PerformanceSelector
  performanceLink?: string
  finalGrade?: FinalGradeInterface
  performanceSelectorData?: PerformanceSelector[]
  setCycleTimelineEvents?: (events: SingleTimelineEventInterface[]) => void
  eventsData: SingleTimelineEventInterface[]
  isLoading?: boolean
}

const resultsTitle = {
  managers: 'Results published to managers',
  employees: 'Results published to employees',
} as const

export const PerformanceTimeline = ({
  data,
  selectedPeriod,
  performanceLink,
  finalGrade,
  performanceSelectorData,
  setCycleTimelineEvents,
  eventsData,
  isLoading,
}: Props) => {
  const currentCycleReviewsPublishingDay = performanceSelectorData?.find(
    ({ id }) => id === selectedPeriod.id,
  )?.reviews_publishing_day

  const { data: settings } = useGetPerformanceSettings()
  const [isCreateGoalPending, setIsCreateGoalPending] = useState(false)
  const [selectedStepId, setSelectedStepId] = useState<string>()

  const { navigateWithEntity } = useOrgEntity()
  const { data: summaryData, isLoading: summaryLoading } = useFetchPerformanceSummary(
    selectedPeriod?.id !== undefined ? String(selectedPeriod?.id) : undefined,
    data.id,
    ReviewCategory.Performance,
  )

  const { employeeGoalsFilters, teamGoalsFilters } = useGetGoalsFilters(
    selectedPeriod,
    data,
    summaryData?.team,
  )

  const { data: employeeGoals, isLoading: isEmployeeGoalsLoading } =
    useGetGoalsWithContentTypeCheck({
      filters: employeeGoalsFilters,
    })

  const { data: teamGoals, isLoading: isTeamGoalsLoading } =
    useGetGoalsWithContentTypeCheck({
      filters: teamGoalsFilters,
    })

  const goalsCount = (teamGoals?.count || 0) + (employeeGoals?.count || 0)

  const { data: contentTypes, isLoading: contentTypesLoading } =
    useGetSelectors<GoalContentType>(selectorKeys.goal_content_types)
  const showStatusPopup = useShowStatusPopup()
  const canViewMeetings = useCanViewMeetingsTab(data)
  const user = useSelector(selectUser)
  const isManager =
    user.id === data.quality_control?.id || user.id === data.line_manager?.id

  const canAddKpi = useSelector(canAddTeamKpi)
  const canAddGoals = !!data.field_options?.permissions?.includes(
    PermissionTypes.CanAddGoals,
  )

  useEffect(() => {
    if (setCycleTimelineEvents) {
      setCycleTimelineEvents(eventsData || [])
    }
  }, [eventsData])

  const timelineEvents = Array.isArray(eventsData) ? eventsData : []

  const initialAccumulator = {
    timelineEventsWithoutResults: [] as SingleTimelineEventInterface[],
    lastStepData: [] as SingleTimelineEventInterface[],
    timeLineEventsMap: new Map() as Map<
      SingleTimelineEventInterface['title'],
      SingleTimelineEventInterface
    >,
  }

  const { timelineEventsWithoutResults, lastStepData, timeLineEventsMap } =
    useMemo(() => {
      if (!timelineEvents) {
        return initialAccumulator
      }

      return timelineEvents.reduce<typeof initialAccumulator>((acc, curr) => {
        acc.timeLineEventsMap.set(curr.title, curr)

        if (curr.category === 'review_results') {
          acc.lastStepData.push(curr)
        } else {
          acc.timelineEventsWithoutResults.push(curr)
        }
        return acc
      }, initialAccumulator)
    }, [timelineEvents])

  const getLastStepData = (): {
    state: 'pending' | 'done' | 'default'
    description: JSX.Element | null
  } => {
    const title = isManager ? resultsTitle.managers : resultsTitle.employees

    const publishedResults = timelineEvents.find(
      ({ title: eventTitle }) => eventTitle === title,
    )

    if (publishedResults) {
      return {
        state: getTimelineStepState(
          publishedResults.start_period,
          publishedResults.end_period,
          publishedResults.category,
        ),
        description: getTimelineStepDescription(publishedResults),
      }
    }

    return { state: 'default', description: null }
  }

  const canChangeGradePublishing = useMemo(() => {
    if (data.line_manager.id !== user.id) {
      // only LM can publish / unpublish grades prematurely
      return false
    }
    // they have the same category and type so we have to rely on title
    const managerPublishDate = lastStepData.find(
      ({ title }) => title === resultsTitle.managers,
    )?.start_period
    const employeePublishDate = lastStepData.find(
      ({ title }) => title === resultsTitle.employees,
    )?.start_period

    if (
      !managerPublishDate ||
      !employeePublishDate ||
      !finalGrade ||
      finalGrade.id === FinalGrade.Poor
    ) {
      return false
    }

    if (
      isAfter(new Date(), new Date(managerPublishDate)) &&
      isBefore(new Date(managerPublishDate), new Date(employeePublishDate))
    ) {
      return true
    }
    return false
  }, [lastStepData, user.id, data.line_manager.id, finalGrade])

  const { state: lastStepState, description: lastStepDescription } = getLastStepData()

  const pendingTimeLineStepData = useMemo(() => {
    const targetEvent = timelineEvents?.filter(event => {
      const state = getTimelineStepState(
        event.start_period,
        event.end_period,
        event.category,
      )
      return state === 'pending'
    })[0]
    return { type: targetEvent?.category, event: targetEvent }
  }, [timelineEvents])

  useEffect(() => {
    setSelectedStepId(undefined)

    if (lastStepState === 'pending') {
      setSelectedStepId('Grade published')
    }

    if (pendingTimeLineStepData.event) {
      setSelectedStepId(pendingTimeLineStepData.event.title)
    }
  }, [pendingTimeLineStepData])

  const activeTimeLineStep = useMemo(() => {
    if (selectedStepId === 'Grade published') {
      return { type: lastStepData[0]?.category, event: undefined }
    }

    if (selectedStepId) {
      const event = timeLineEventsMap.get(selectedStepId)
      return { type: event?.category, event }
    }

    return pendingTimeLineStepData
  }, [selectedStepId, pendingTimeLineStepData])

  if (isLoading || summaryLoading || isEmployeeGoalsLoading || isTeamGoalsLoading) {
    return <SectionLoader />
  }

  if (!timelineEvents) {
    return null
  }

  const onAddGoalsClick = async () => {
    try {
      setIsCreateGoalPending(true)
      const response = await createDraftGoal({
        owner: { id: data.id },
        content_type: contentTypes?.find(({ model }) => model === 'employees'),
        object_id: data.id,
      })
      navigateWithEntity(pathToUrl(ROUTES.FORMS.GOAL.EDIT, { id: response.data.id }), {
        reviewCycleId: selectedPeriod.id,
        isNew: true,
      })
    } catch (err) {
      captureException(err)

      showStatusPopup({
        status: 'error',
        title: 'Failed to create goal',
        description: getStringMessageFromError(
          err,
          'Something went wrong. Please try again.',
        ),
      })
    } finally {
      setIsCreateGoalPending(false)
    }
  }

  const renderButton = (category: string | undefined) => {
    const isKPICategoryAddGoal =
      category === 'kpi' ? canAddGoals : category === 'kpi' && canAddKpi
    const isGoalCategoryAddGoal = category === 'goal' && canAddGoals
    if (
      selectedPeriod?.status === ReviewCycleStatus.ongoing &&
      (isKPICategoryAddGoal || isGoalCategoryAddGoal)
    ) {
      return (
        <Flex mr="s-6">
          {contentTypesLoading ? (
            <ActionButtonSkeleton />
          ) : (
            <ActionButton
              useIcon="Plus"
              onClick={onAddGoalsClick}
              pending={isCreateGoalPending}
            >
              Add goals
            </ActionButton>
          )}
        </Flex>
      )
    }

    if (category === 'meeting') {
      return (
        <>
          <ActionButton
            useIcon="Plus"
            onClick={() => {
              navigateTo(
                pathToUrl(ROUTES.FORMS.EMPLOYEE.FEEDBACK.ONE_TO_ONE, {
                  userId: data.id,
                }),
              )
            }}
          >
            Add note
          </ActionButton>
        </>
      )
    }

    if (category === 'promotion') {
      return (
        <>
          <NominateForPromotion
            text="Nominate"
            icon="Plus"
            data={data}
            cycleId={selectedPeriod.id}
          />
          <ViewNominationButton data={data} cycleId={selectedPeriod.id} />
        </>
      )
    }

    return null
  }

  const renderPerformanceReviewers = (
    event: SingleTimelineEventInterface | undefined,
  ) => {
    if (!event?.reviews?.length) {
      if (event?.category === 'kpi' || event?.category === 'goal') {
        return selectedPeriod?.status === ReviewCycleStatus.closed ? (
          <Flex justifyContent="space-between" alignItems="center">
            <Flex gap="s-16">
              <Circle bg={Token.color.greyTone10} size={40}>
                <Icon name="InfoSign" size={24} />
              </Circle>
              <Flex gap="s-2" flexDirection="column">
                <Text whiteSpace="nowrap" variant="emphasis1">
                  No goal assigned
                </Text>
                <Text color={Token.color.greyTone50} whiteSpace="nowrap" variant="body2">
                  This employee has not been assigned any goals for this quarter.
                  Assessment is provided based on Team Goals.
                </Text>
              </Flex>
            </Flex>
            {renderButton(event.category)}
          </Flex>
        ) : (
          <Flex justifyContent="space-between" alignItems="center">
            <Flex gap="s-16" alignItems="center">
              <Circle bg={Token.color.greyTone10} size={40}>
                <Icon name="InfoSign" size={24} />
              </Circle>
              <Flex gap="s-2" flexDirection="column">
                <Text variant="emphasis1">
                  {goalsCount ? 'Performance goals are set' : 'Performance goals pending'}
                </Text>
                {!goalsCount && (
                  <Text
                    color={Token.color.greyTone50}
                    whiteSpace="nowrap"
                    variant="body2"
                  >
                    This employee has not been assigned any goals yet.
                  </Text>
                )}
              </Flex>
            </Flex>
            {renderButton(event.category)}
          </Flex>
        )
      }
      return null
    }

    const isGoalsStep = event.category === 'kpi' || event.category === 'goal'
    const isCalibrationStep = event.category === 'calibration'

    const currentUserReview = event.reviews.find(
      review => review.reviewer?.id === user.id,
    )
    const visibleReviews = isCalibrationStep
      ? event.reviews.filter(({ reviewer }) => reviewer.id !== data.id)
      : event.reviews
    const buttonDisabled = currentUserReview ? !currentUserReview.can_submit : true

    let state = ButtonStates.Add
    if (currentUserReview?.status === Statuses.completed) {
      state = ButtonStates.Edit
    }
    if (currentUserReview?.status === Statuses.draft) {
      state = ButtonStates.Continue
    }

    const reviewLink = ROUTES.FORMS.EMPLOYEE_PERFORMANCE_LAYOUT

    const subText = isGoalsStep ? 'Set quarterly goals' : ''

    return (
      <Group style={transparentThemeBackgroundOverrides}>
        <StepReviewersList
          summaryData={summaryData}
          isCalibrationStep={isCalibrationStep}
          reviewsPublishingDay={currentCycleReviewsPublishingDay}
          secondaryActionButton={renderButton(event.category)}
          reviews={visibleReviews}
          subText={subText}
          onClickAddReview={e => {
            e.stopPropagation()
            navigateTo(
              pathToUrl(reviewLink, {
                id: currentUserReview?.id,
                employeeId: data.id,
              }),
            )
          }}
          buttonDisabled={buttonDisabled}
          icon={ButtonIcon[state]}
          title={ButtonTitles[state]}
          performanceLink={isCalibrationStep ? undefined : performanceLink}
        />
      </Group>
    )
  }

  const events = timelineEventsWithoutResults
  const isEmptyReviewers =
    !activeTimeLineStep.event?.reviews?.length && activeTimeLineStep.event?.category
      ? !['kpi', 'goals'].includes(activeTimeLineStep.event.category)
      : false

  const getIsClosedEvent = () => {
    if (selectedStepId === 'Grade published') {
      return currentCycleReviewsPublishingDay
        ? isPast(new Date(currentCycleReviewsPublishingDay))
        : undefined
    }
    return activeTimeLineStep?.event?.end_period
      ? isPast(new Date(activeTimeLineStep?.event?.end_period))
      : undefined
  }

  return (
    <>
      <ProgressSteps>
        {events.length > 0 &&
          events.map((event, i) => {
            if (event.category === 'meeting' && !canViewMeetings) {
              return null
            }

            const state = getTimelineStepState(
              event.start_period,
              event.end_period,
              event.category,
            )
            const description = getTimelineStepDescription(
              event,
              selectedPeriod.is_manual_stage_switch_enabled,
            )

            const shouldRenderStep =
              event.category === 'calibration' ? settings?.enable_calibration : true

            if (shouldRenderStep && event.category !== 'meeting') {
              return (
                <ProgressStep
                  indicatorColor={
                    state === 'pending' ? Token.color.accent : Token.color.teal
                  }
                  style={{ cursor: 'pointer' }}
                  data-testid={`${event.title}--${state}`}
                  key={i}
                  state={state}
                  onClick={() => setSelectedStepId(event.title)}
                  paddingTop="s-6"
                  paddingX="s-6"
                  paddingBottom="s-16"
                  borderRadius="r6"
                  bg={selectedStepId === event.title ? Token.color.grey50_10 : undefined}
                >
                  {event.title && (
                    <ProgressStep.Title>
                      {event.category === 'promotion'
                        ? 'Nomination for promotion'
                        : event.title}
                    </ProgressStep.Title>
                  )}
                  <ProgressStep.Description>{description}</ProgressStep.Description>
                </ProgressStep>
              )
            }
            return null
          })}

        <ProgressStep
          indicatorColor={
            lastStepState === 'pending' ? Token.color.accent : Token.color.teal
          }
          style={{ cursor: 'pointer' }}
          data-testid={`Grade published--${lastStepState}`}
          state={lastStepState}
          onClick={() => setSelectedStepId('Grade published')}
          paddingTop="s-6"
          paddingBottom="s-16"
          borderRadius="r6"
          bg={
            selectedStepId === 'Grade published' || lastStepState === 'pending'
              ? Token.color.grey50_10
              : undefined
          }
        >
          <ProgressStep.Title>Grade published</ProgressStep.Title>
          {!selectedPeriod.is_manual_stage_switch_enabled && (
            <ProgressStep.Description>{lastStepDescription}</ProgressStep.Description>
          )}
        </ProgressStep>
      </ProgressSteps>
      {selectedStepId && activeTimeLineStep?.type && (
        <Banner
          mt="s-12"
          labelButtonClear="Clear"
          onClear={() => setSelectedStepId(undefined)}
        >
          <Banner.Content>
            <PerfomanceStepDetails
              isManualCycle={selectedPeriod?.is_manual_stage_switch_enabled}
              isClosedCycle={getIsClosedEvent()}
              isEmptyReviewers={isEmptyReviewers}
              publishedResults={lastStepData}
              finalGrade={finalGrade}
              type={activeTimeLineStep?.type}
              eventStartDate={activeTimeLineStep.event?.start_period}
              eventEndDate={activeTimeLineStep.event?.end_period}
              reviewsElements={renderPerformanceReviewers(activeTimeLineStep.event)}
              actionsElements={renderButton(activeTimeLineStep.type)}
              canChangeGradePublishing={canChangeGradePublishing}
              selectedCycleId={selectedPeriod?.id}
              employee={data}
            />
          </Banner.Content>
        </Banner>
      )}
    </>
  )
}
